<template>
    <div class="message-text">
        <div class="action-schedule">
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span>매칭 성공!</span>
                </div>
                <div class="message-content">
                    {{ text }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionAiMatched',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        text() {
            const { age, job_name: job, region_name: region } = this.content

            return `${job} / ${age} / ${region} 회원과 매칭되었어요! 약속 일정을 바로 잡아보세요`
        },
    },
}
</script>

<style scoped lang="scss">
.action-schedule {
    padding: 0 0 12px 0;
}
</style>
